<template>
  <div class="move-game-container layout-border" :class="'lesson' + lessonNum">
    <div class="move-game-box position-relative">
      <PageButton
        :bottomCornerButtonList="bottomCornerButtonList"
        :isLastStep="isLastStep"
        @clickNext="nextPage"
      ></PageButton>
      <div class="move-game-area">
        <div
          class="move-game-content"
          @click="handleClickBgImg"
          ref="moveGameBox"
        >
          <!-- 背景图 -->
          <img :src="bgImg" alt="" />
          <div class="frontImgWrapper">
            <img :src="frontImg" alt="" />
          </div>
          <!-- 学生头像 -->
          <div class="student-img" ref="student" :class="'step-' + step">
            <img :src="studentImg" alt="" />
          </div>

          <!-- answer area -->

          <div class="answer-area" v-if="isAction" :class="'dialog-' + step">
            <img
              v-if="isQuestionOpen"
              src="@/assets/img/16-GAMES/G12-map/bubble-question.svg"
              alt=""
            />
            <img v-if="isAnswerOpen" :src="answerImg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";

export default {
  components: {
    PageButton,
  },
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    frontImg: {
      type: String,
      require: true,
    },
    studentImg: {
      type: String,
      require: true,
    },
    lessonNum: {
      type: Number,
      require: true,
    },

    durationArr: {
      type: Array,
      require: true,
    },
    answerImg: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 1,
            endNumberRange: 3,
          },
        ],
      },

      gameHeight: null,
      gameWidth: null,
      step: 0,
      isAnswerOpen: false,
      isQuestionOpen: false,
      isAction: false,
      scrollDom: null,
      scrollWidth: null,
      distance: 0,
      handleClickBgImgValue: false,
      scrollSpeed: 2,
    };
  },

  mounted() {
    this.$bus.$on("moveGamePage", () => {
      this.handleClickBgImg(1);
    });
    setTimeout(() => {
      this.$nextTick(() => {
        this.scrollDom = this.$refs.moveGameBox;
        this.scrollWidth = this.scrollDom.scrollWidth;
      });
    }, 100);
    this.$bus.$on("clickMoveGame", () => {
      this.handleClickBgImg(1);
    });
  },
  beforeDestroy() {
    this.$bus.$off("moveGamePage");
    this.$bus.$off("clickMoveGame");
  },
  watch: {
    step(value) {
      if (this.handleClickBgImgValue) {
        const data = {
          clickType: 20011,
          data: { value },
          text: "DragGameBtn点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      }
    },
    isQuestionOpen(value) {
      if (this.handleClickBgImgValue) {
        if (!value) {
          const data = {
            clickType: 20011,
            data: { value },
            text: "DragGameBtn点击socket",
          };
          this.sendSocketInfo = { ...data };
          this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
        }
      }
    },
  },
  methods: {
    ControlScroll(step) {
      this.scrollMove(this.durationArr[step - 1]);
    },
    scrollMove(num) {
      const speed = this.scrollSpeed;
      let timer = setInterval(() => {
        this.distance += speed;
        this.scrollDom.scrollLeft = this.distance;
        if (this.distance / this.scrollWidth >= num) {
          clearInterval(timer);
        }
      }, 10);
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickBgImg(type) {
      console.log(this.step);
      if (this.isLastStep === true) {
        return;
      }
      if (type != 1) {
        this.handleClickBgImgValue = true;
      } else {
        this.handleClickBgImgValue = false;
      }
      if (this.step >= this.durationArr.length + 1) {
        return;
      }
      if (this.isQuestionOpen) {
        this.isQuestionOpen = false;
        this.isAnswerOpen = true;
        playCorrectSound(true, false);
        if (this.step === this.durationArr.length) {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound();
        }
        // this.
      } else {
        this.step++;
        if (this.step > 1) {
          this.ControlScroll(this.step);
        }
        if (this.step >= this.durationArr.length + 1) {
          return;
        }
        setTimeout(() => {
          this.isAction = true;
          this.isQuestionOpen = true;
        }, 2000);
        this.isAnswerOpen = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.move-game-container {
  position: relative;
  .move-game-box {
    width: 100%;
    height: 100%;
    background-color: #f7f4e0;
    border-radius: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    position: relative;
    .move-game-area {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      border-radius: 58px;
      .move-game-content {
        height: 100%;
        position: relative;
        border-radius: 58px;

        :hover {
          cursor: pointer;
        }
        overflow-x: scroll;
        img {
          // width: 100%;
          height: 100%;
        }

        .picture-content-rocket-content {
          height: 22%;
          position: absolute;
          // bottom: 22%;
          // left: 8%;
          z-index: 6;
          img {
            height: 100%;
          }
        }
        .student-img {
          height: 22%;
          position: absolute;
          bottom: 30%;
          left: 0%;
          transition: all 2s;
          z-index: 4;

          img {
            height: 100%;
          }
        }
        .answer-area {
          position: absolute;
          height: 20%;
          z-index: 10;
          img {
            height: 100%;
          }
        }
        .frontImgWrapper {
          position: absolute;
          inset: 0px;
          z-index: 5;
        }
      }
    }
    ::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
.lesson2 {
  .student-img {
    bottom: 30%;
    left: 0%;
  }
  .step-1 {
    bottom: 24vh !important;
    left: 24vh !important;
  }
  .dialog-1 {
    bottom: 42vh !important;
    left: 31vh !important;
  }
  .step-2 {
    bottom: 37vh !important;
    left: 60vh !important;
  }
  .dialog-2 {
    bottom: 55vh !important;
    left: 65vh !important;
  }
  .step-3 {
    bottom: 26vh !important;
    left: 90vh !important;
  }
  .dialog-3 {
    bottom: 46vh !important;
    left: 95vh !important;
  }
  .step-4 {
    bottom: 45vh !important;
    left: 119vh !important;
  }
  .dialog-4 {
    bottom: 64vh !important;
    left: 124vh !important;
  }
  .step-5 {
    bottom: 29vh !important;
    left: 143vh !important;
  }
  .dialog-5 {
    bottom: 45vh !important;
    left: 148vh !important;
    img {
      height: 80% !important;
    }
  }
  .step-6 {
    bottom: 29vh !important;
    left: 143vh !important;
  }
  .dialog-6 {
    bottom: 45vh !important;
    left: 162vh !important;
    img {
      height: 80% !important;
    }
  }
}

.lesson4 {
  .student-img {
    bottom: 15vh !important;
    left: 50vh !important;
  }
  .step-1 {
    bottom: 20vh !important;
    left: 28vh !important;
  }
  .dialog-1 {
    bottom: 39vh !important;
    left: 32vh !important;
  }
  .step-2 {
    bottom: 37vh !important;
    left: 43vh !important;
  }
  .dialog-2 {
    bottom: 55vh !important;
    left: 50vh !important;
  }
  .step-3 {
    bottom: 44vh !important;
    left: 94vh !important;
  }
  .dialog-3 {
    bottom: 61vh !important;
    left: 99vh !important;
  }
  .step-4 {
    bottom: 8vh !important;
    left: 138vh !important;
  }
  .dialog-4 {
    bottom: 28vh !important;
    left: 140vh !important;
  }
  .step-5 {
    bottom: 8vh !important;
    left: 138vh !important;
  }
  .dialog-5 {
    bottom: 48vh !important;
    left: 157vh !important;
  }
  .step-6 {
    bottom: 8vh !important;
    left: 138vh !important;
  }
  .dialog-6 {
    bottom: 48vh !important;
    left: 157vh !important;
  }
}

.lesson6 {
  .student-img {
    bottom: 19vh !important;
    left: 8vh !important;
  }
  .step-1 {
    bottom: 18vh !important;
    left: 35vh !important;
  }
  .dialog-1 {
    bottom: 34vh !important;
    left: 40vh !important;
  }
  .step-2 {
    bottom: 47vh !important;
    left: 60vh !important;
  }
  .dialog-2 {
    bottom: 63vh !important;
    left: 66vh !important;
  }
  .step-3 {
    bottom: 33vh !important;
    left: 94vh !important;
  }
  .dialog-3 {
    bottom: 52vh !important;
    left: 99vh !important;
  }
  .step-4 {
    bottom: 26vh !important;
    left: 135vh !important;
  }
  .dialog-4 {
    bottom: 45vh !important;
    left: 141vh !important;
  }
  .step-5 {
    bottom: 26vh !important;
    left: 135vh !important;
  }
  .dialog-5 {
    bottom: 48vh !important;
    left: 157vh !important;
  }
}
.lesson7 {
  .student-img {
    bottom: 25vh !important;
    left: 5vh !important;
  }
  .step-1 {
    bottom: 24vh !important;
    left: 33vh !important;
  }
  .dialog-1 {
    bottom: 38vh !important;
    left: 59vh !important;
  }
  .step-2 {
    bottom: 10vh !important;
    left: 97vh !important;
  }
  .dialog-2 {
    bottom: 26vh !important;
    left: 123vh !important;
  }
  .step-3 {
    bottom: 30vh !important;
    left: 144vh !important;
  }
  .dialog-3 {
    bottom: 45vh !important;
    left: 170vh !important;
  }
  .step-4 {
    bottom: 4vh !important;
    left: 191vh !important;
  }
  .dialog-4 {
    bottom: 19vh !important;
    left: 218vh !important;
  }
  .step-5 {
    bottom: 26vh !important;
    left: 245vh !important;
  }
  .dialog-5 {
    bottom: 44vh !important;
    left: 268vh !important;
  }
  .step-6 {
    bottom: 19vh !important;
    left: 307vh !important;
  }
  .dialog-6 {
    bottom: 36vh !important;
    left: 332vh !important;
  }
  .step-7 {
    bottom: 34vh !important;
    left: 382vh !important;
  }
  .dialog-7 {
    bottom: 51vh !important;
    left: 408vh !important;
  }
  .step-8 {
    bottom: 19vh !important;
    left: 438vh !important;
  }
  .dialog-8 {
    bottom: 36vh !important;
    left: 465vh !important;
  }
  .step-9 {
    bottom: 31vh !important;
    left: 499vh !important;
  }
  .dialog-9 {
    bottom: 48vh !important;
    left: 526vh !important;
  }
  .step-10 {
    bottom: 47vh !important;
    left: 567vh !important;
  }
  // .dialog-10 {
  //   bottom: 48vh !important;
  //   left: 526vh !important;
  // }
}
.lesson10 {
  .student-img {
    bottom: 25vh !important;
    left: 5vh !important;
    height: 27% !important;
  }
  .step-1 {
    bottom: 24vh !important;
    left: 17vh !important;
  }
  .dialog-1 {
    bottom: 48vh !important;
    left: 40vh !important;
  }
  .step-2 {
    bottom: 38vh !important;
    left: 54vh !important;
  }
  .dialog-2 {
    bottom: 62vh !important;
    left: 75vh !important;
  }
  .step-3 {
    bottom: 20vh !important;
    left: 90vh !important;
  }
  .dialog-3 {
    bottom: 55vh !important;
    left: 120vh !important;
  }
  .step-4 {
    bottom: 40vh !important;
    left: 150vh !important;
  }
  .dialog-4 {
    bottom: 63vh !important;
    left: 164vh !important;
  }
}

.lesson25 {
  .student-img {
    bottom: 25vh !important;
    left: 5vh !important;
    height: 27% !important;
  }
  .step-1 {
    bottom: 25vh !important;
    left: 22vh !important;
  }
  .dialog-1 {
    bottom: 48vh !important;
    left: 22vh !important;
  }
  .step-2 {
    bottom: 25vh !important;
    left: 47vh !important;
  }
  .dialog-2 {
    bottom: 48vh !important;
    left: 47vh !important;
  }
  .step-3 {
    bottom: 25vh !important;
    left: 63vh !important;
  }
  .dialog-3 {
    bottom: 48vh !important;
    left: 63vh !important;
  }
  .step-4 {
    bottom: 25vh !important;
    left: 115vh !important;
  }
  .dialog-4 {
    bottom: 48vh !important;
    left: 115vh !important;
  }
  .step-5 {
    bottom: 25vh !important;
    left: 168vh !important;
  }
  .dialog-5 {
    bottom: 48vh !important;
    left: 168vh !important;
  }
}
</style>
